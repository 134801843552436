<!-- 账单流水 -->
<template>
  <div>billDetails</div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {}
  },
  methods: {},
  mounted () {
  }
}
</script>
